<template>
  <div id="app">
    <ChatRoom />
  </div>
</template>

<script>
import ChatRoom from './components/ChatRoom.vue';

export default {
  components: {
    ChatRoom
  }
};
</script>
