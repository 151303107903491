import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import { ref } from "vue";

export function useAgoraService() {
  const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  const localAudioTrack = ref(null);
  const localVideoTrack = ref(null);
  const users = ref(new Map());

  async function fetchAgoraToken(channelName, uid) {
    const response = await axios.post("https://api.tinipu.com/create-agora-token", {
      channelName,
      uid,
    });
    return response.data.token;
  }

  async function joinChannel(appId, channelName, uid, remoteStream) {
    const token = await fetchAgoraToken(channelName, uid);
    await client.join(appId, channelName, token, uid);

    client.on("user-joined", handleUserJoined);
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);

    async function handleUserPublished(user, mediaType) {
      await client.subscribe(user, mediaType);
      if (mediaType === "video") {
        user.videoTrack.play(remoteStream);
        users.value.set(user.uid, user); 
      } else if (mediaType === "audio") {
        user.audioTrack.play();
      }
    }

    async function handleUserJoined(user) {
      console.log("User joined:", user.uid);

      if (user.videoTrack) {
        user.videoTrack.play(remoteStream);
      }

      for (const [, user] of users.value) {
        if (user.videoTrack) {
          user.videoTrack.play(remoteStream);
        }
      }
    }

    async function handleUserUnpublished(user) {
      const remoteDiv = document.getElementById(`remote-video-${user.uid}`);
      if (remoteDiv) {
        remoteDiv.remove();
      }
      users.value.delete(user.uid); // Remove from tracked users
    }
  }

  async function createTracks(el, facingMode) {
    localAudioTrack.value = await AgoraRTC.createMicrophoneAudioTrack();
    localVideoTrack.value = await AgoraRTC.createCameraVideoTrack({
      facingMode: facingMode,
    });

    localVideoTrack.value.play(el);
  }

  async function publish() {
    await client.publish([localAudioTrack.value, localVideoTrack.value]);
  }

  async function leaveChannel() {
    localAudioTrack.value.close();
    localVideoTrack.value.close();
    await client.leave();
  }

  return {
    client,
    localAudioTrack,
    localVideoTrack,
    AgoraRTC,
    joinChannel,
    createTracks,
    publish,
    leaveChannel,
  };
}
