<template>
  <div class="chatroom">
    <div class="streams">
      <div id="local-stream" ref="localStream" class="video-stream"></div>
      <div id="remote-stream" ref="remoteStream" class="video-stream"></div>
    </div>
    <button @click="handleJoinChannel">Join Channel</button>
    <button @click="handleLeaveChannel">Leave Channel</button>
    <button @click="toggleCamera">Flip Camera</button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAgoraService } from "../services/agoraService";

// Agora service and state
const appId = '22a7abeed850420095c8a2c6ac31ba57';
const channel = ref('test');
const uid = ref(Math.floor(Math.random() * 10000));
const localStream = ref(null);
const remoteStream = ref(null);

const isUsingFrontCamera = ref(true); // Toggle camera state
let localAudioTrack = ref(null);
let localVideoTrack = ref(null);

const {
  joinChannel,
  leaveChannel,
  AgoraRTC,
  client,
} = useAgoraService(appId);

// Create tracks with front or back camera
async function createTracks(el, facingMode) {
  localAudioTrack.value = await AgoraRTC.createMicrophoneAudioTrack();
  localVideoTrack.value = await AgoraRTC.createCameraVideoTrack({
    facingMode: facingMode,
  });
  
  localVideoTrack.value.play(el); // Play video in the provided element
}

// Join the channel and create tracks
async function handleJoinChannel() {
  await joinChannel(appId, channel.value, uid.value, remoteStream.value);
  await createAndPublishTracks();
}

// Create and publish local tracks
async function createAndPublishTracks() {
  await createTracks(localStream.value, isUsingFrontCamera.value ? 'user' : 'environment');
  await client.publish([localAudioTrack.value, localVideoTrack.value]);
}

// Toggle between front and back cameras
async function toggleCamera() {
  // Flip the camera
  isUsingFrontCamera.value = !isUsingFrontCamera.value;

  // Stop and close the current video track
  if (localVideoTrack.value) {
    await localVideoTrack.value.stop();
    await localVideoTrack.value.close();
  }

  // Create and publish new tracks with the updated camera mode
  // await createAndPublishTracks();
  await createTracks(localStream.value, isUsingFrontCamera.value ? 'user' : 'environment');
}

// Leave the channel and stop tracks
async function handleLeaveChannel() {
  await leaveChannel();

  if (localAudioTrack.value) {
    await localAudioTrack.value.stop();
    await localAudioTrack.value.close();
  }

  if (localVideoTrack.value) {
    await localVideoTrack.value.stop();
    await localVideoTrack.value.close();
  }

  localStream.value.innerHTML = "";
  remoteStream.value.innerHTML = "";
}
</script>

<style scoped>
.streams {
  display: flex;
  gap: 10px;
}

.video-stream {
  width: 500px;
  height: 400px;
  border: 1px solid;
}
</style>
